import * as Ably from 'ably';

import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  Slide,
} from '@mui/material/';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { Suspense, lazy } from 'react';

import { AblyProvider } from 'ably/react';
import { AreasProgressionProvider } from 'context/AreasProgressionContext';
import Auth0ProviderWithHistory from 'components/auth/auth0';
import { BrandProvider } from 'context/BrandContext';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './globalStyles';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SnackbarProvider } from 'notistack';
import { StyledEngineProvider } from '@mui/material/styles';
import Themes from './themes';
import { UserProvider } from './context/UserContext';
import reportWebVitals from './reportWebVitals';

const App = lazy(() => import('./App'));
const ablyClient = await new Ably.Realtime(process.env.REACT_APP_ABLY_API_KEY);
const root = ReactDOM.createRoot(document.getElementById('root'));
let showDevTools = true;
if (process.env.REACT_APP_PUBLIC_API_MOCKING === 'enabled') {
  // eslint-disable-next-line global-require
  // require('./mocks/browser');
  showDevTools = false;
}

await ablyClient.connection.on('failed', () => {
  console.log('Ably connection failed');
  return ablyClient.connection.close();
});

const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <AblyProvider client={ablyClient}>
                <BrandProvider>
                  <StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={Themes.default}>
                      <CssBaseline />
                      <GlobalStyles />
                      <SnackbarProvider
                        preventDuplicate
                        hideIconVariant={false}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                          autoHideDuration: 3000,
                        }}
                        TransitionComponent={Slide}
                      >
                        <AreasProgressionProvider>
                          <App />
                          {showDevTools && <ReactQueryDevtools />}
                        </AreasProgressionProvider>
                      </SnackbarProvider>
                    </MuiThemeProvider>
                  </StyledEngineProvider>
                </BrandProvider>
              </AblyProvider>
            </Suspense>
          </UserProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Auth0ProviderWithHistory>
  </React.StrictMode>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
